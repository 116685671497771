import { useEffect, useState } from "react";
import "./App.css";
import Login from "./components/Login";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  DASHBOARD_ROUTE,
  ENTRY_PATH,
  INITIAL_ROUTE,
  ORGANIZATION_SETTING_ROUTE,
  PRIVACY_POLICY,
  SIGNIN_ROUTE,
  SIGNUP_ROUTE,
  SUPPORT_ROUTE,
  TEAMS_AUTH_PATH,
  TERMS_ROUTE,
} from "./constants/RoutePath";
import {
  PrivateRoute,
  PublicRoute,
  authHeader,
  getCookie,
  getDomain,
  getSideFlag,
} from "./components/common/mainfunctions";

import { useDispatch, useSelector } from "react-redux";

import Layouts from "./components/Layouts";
import SignupdomainOption from "./components/SignupdomainOption";
import CopyRedirect from "./components/CopyRedirect";
import ApprovedNotification from "./components/Notify/ApprovedNotification";
import RejectNotification from "./components/Notify/RejectNotification";
import MailboxList from "./components/mailbox/MailboxList";
import * as microsoftTeams from "@microsoft/teams-js";
import Support from "./components/TermsandUse/Support";
import Policy from "./components/TermsandUse/Policy";
import Terms from "./components/TermsandUse/Terms";
import LandingPage from "./components/LandingPage";
import LandingPageTeams from "./components/LandingPageTeams";
import notfound from "./images/notfound.svg";
import Entry from "./components/Entry";
import TeamsAuth from "./components/TeamsAuth";

function App() {
  const location = useLocation();
  const [sideFlag, setSideFlag] = useState(false);
  let pathName = location.pathname;
  let arr = pathName.toString().split("/");
  let currentPath = arr[1];
  let cpa = "/".concat(currentPath);
  if (sessionStorage.getItem("DOMAIN") == "") {
    sessionStorage.setItem("DOMAIN", cpa);
  }
  const cooktoken = getCookie("authToken");
  console.log("cookies", cooktoken);
  let dystrchk = "http://localhost:3000/signup";
  console.log(dystrchk.split(".")[0].includes("localhost") === false); // False, no subdomain

  dystrchk = "http://localhost:3000/signup";
  console.log(dystrchk.split(".")[0].includes("localhost") === false); // True, subdomain present

  let dystr = window.location.href;
  console.log("has domain1", dystr);

  // const hasSubdomain = dystr.includes('://localhost:');
  const hasSubdomain = dystr.includes("://devhrassist.");
  // const hasSubdomain = dystr.includes("://hrassist.");
  console.log("has domain", hasSubdomain);
  // let dystr ='devo365tc3.devhrassist.app';
  let pa = false;
  console.log(dystr, dystr.split("."));

  if (dystr.split(".")[0] === "devhrassist") {
    pa = true;
  }
  console.log(pa);
  // if (!dystr.includes("portal") && !dystr.includes(".")) {
  //   dystr = "";
  // }
  // if (dystr.includes(".com")) {
  //   dystr = dystr.split(".com")[0];
  // }
  // if (dystr.includes(".app")) {
  //   dystr = dystr.split(".app")[0];
  //   console.log("dystrin", dystr);
  // }
  // if (dystr.includes(".o365toolkit")) {
  //   dystr = dystr.split(".azurewebsites.net")[0];
  // } else if (dystr.includes("o365toolkit")) {
  //   dystr = dystr.split(".azurewebsites.net")[0];
  // }
  let t1 = authHeader();
  useEffect(() => {
    // Check if the app is running inside Microsoft Teams
    if (window.location !== window.parent.location) {
      setSideFlag(true);
      localStorage.setItem("SIDE_FLAG", true);
      microsoftTeams.initialize();
    } else {
      console.log("App is running outside of Microsoft Teams");
      localStorage.setItem("SIDE_FLAG", false);
    }
  }, []);

  // Use URL object to extract the hostname
  let url = new URL(dystrchk);
  let hostname = url.hostname;
  console.log(hostname);
  // let isSubdomain = dystr.split("localhost")[0].includes(".") ;
  let isSubdomain = dystr.split("devhrassist.app")[0].includes(".");
  // let isSubdomain = dystr.split("hrassist.app")[0].includes(".");
  console.log(dystr.split("localhost"), isSubdomain);

  return (
    <>
      <Routes>
        {/* <Route exact path={"/mailbox"} element={<MailboxList />} /> */}
        <Route
          exact
          path={"/approvenotification/:id"}
          element={
            // <PublicRoute>

            <ApprovedNotification />
            // </PublicRoute>
          }
        />
        <Route
          exact
          path={ENTRY_PATH}
          element={
            // <PublicRoute>

            <Entry />
            // </PublicRoute>
          }
        />
        <Route exact path={TEAMS_AUTH_PATH} element={<TeamsAuth />} />
        <Route
          exact
          path={"/rejectnotification/:id"}
          element={
            <PublicRoute>
              <RejectNotification />
            </PublicRoute>
          }
        />
        <Route
          exact
          path={"/redirect"}
          element={
            // <PublicRoute>
            <CopyRedirect />
            //  </PublicRoute>
          }
        />
        <Route
          exact
          path={"/signin"}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          exact
          path={TERMS_ROUTE}
          element={
            <PublicRoute>
              <Terms />
            </PublicRoute>
          }
        />
        <Route
          exact
          path={PRIVACY_POLICY}
          element={
            <PublicRoute>
              <Policy />
            </PublicRoute>
          }
        />
        <Route
          exact
          path={SUPPORT_ROUTE}
          element={
            <PublicRoute>
              <Support />
            </PublicRoute>
          }
        />
        <Route
          index
          path="/*"
          element={
            <PrivateRoute>
              <Layouts />
            </PrivateRoute>
          }
        ></Route>

        <Route
          exact
          path={SIGNUP_ROUTE}
          element={
            isSubdomain === false ? (
              <SignupdomainOption />
            ) : (
              <>
                <div className="center-container">
                  <div className="image-container centered-image">
                    <img
                      src={notfound}
                      style={{ width: "100%" }}
                      alt="Main Image"
                      className="main-image centered-image"
                    />
                  </div>
                </div>
              </>
            )
          }
        />
        {console.log(getSideFlag(), pa)}
        <Route
          exact
          path={INITIAL_ROUTE}
          element={
            hasSubdomain ? <LandingPageTeams /> : <Navigate to="/signin" />
          }

          // <Navigate to={pa == true ? SIGNUP_ROUTE : SIGNIN_ROUTE} />
          // <Navigate
          //   to={!t1&&dystr.includes('devhrassist')?SIGNUP_ROUTE:
          //     !t1 && dystr.includes(".")
          //       ? SIGNIN_ROUTE
          //       : t1 && dystr.includes(".")
          //       ? DASHBOARD_ROUTE
          //       : dystr == "portal" || dystr == ""
          //       ? SIGNUP_ROUTE
          //       : ""
          //   }
          // />
        />
        <Route
          exact
          path={"/signin"}
          element={
            //  <Navigate to={dystr.includes('.')?SIGNIN_ROUTE:SIGNUP_ROUTE} />
            <Navigate
              to={
                !t1 && dystr.includes(".")
                  ? SIGNIN_ROUTE
                  : t1 && dystr.includes(".")
                  ? ORGANIZATION_SETTING_ROUTE
                  : SIGNUP_ROUTE
              }
            />
          }
        />
      </Routes>
    </>
  );
}

export default App;
