import React from "react";
import Navbar from "../layout/Navbar";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import editIcon from "../../images/editIcon.png";
import outIcon from "../../images/outicon.png";
import { useNavigate } from "react-router-dom";

import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import { useEffect } from "react";
import { EDIT_ACCOUNTDETAILS_URL, USER_PROFILE_URL } from "../common/ApiUrl";
import {
  CustomMessage,
  authHeader,
  getDomain,
  getRole,
  getSideFlag,
  getTentant,
} from "../common/mainfunctions";
import API from "../common/API";
import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

export default function AccountSettingIndex() {
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  const tok = authHeader();
  const [mobFlag, setMobFlag] = useState(false);

  const userDet = useSelector((ud) => ud.UserDetailsReducer);
  const {
    msIntegrateFlag = "",
    disconnectFlag = "",
    permissionAddedFlag = "",
    powershellPermissionFlag = "",
    botAppInstallFlag = "",
  } = userDet || {};
  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let domain = getDomain();
  const [details, setDetails] = useState();
  const editProfile = () => {
    navigation("/account-edit");
  };
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);
  const getUserDetails = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: USER_PROFILE_URL,
    };
    try {
      const response = await API(options);
      if (response.status === 200) {
        const { status, data } = response.data;
        setDetails(data);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
      }
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);
  const handleChange = async () => {
    const { firstName, lastName, email, profilePath, awayFlag, aboutMe } =
      details;
    const formData = new FormData();

    if (awayFlag == 1) {
      formData.append("awayFlag", 0);
    } else {
      formData.append("awayFlag", 1);
    }
    formData.append("file", null);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("aboutMe", aboutMe);
    formData.append("profilePath", profilePath);
    const options = {
      method: "POST",
      url: EDIT_ACCOUNTDETAILS_URL,
      headers: authHeader(),
      data: formData,
    };
    try {
      var apiResponse = await API(options);
      const { status, message } = apiResponse.data;
      if (status === "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getUserDetails();
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    }
  };
  useEffect(() => {
    const handleMessage = (event) => {
      const { data } = event;
      if (data && data.type === "logout") {
        // localStorage.clear();
        // navigation("/signin");
        event.source.postMessage({ type: "logout" }, event.origin);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  const isInTeams = () => {
    return window.self !== window.top;
  };
  function clearCookie(name) {
    document.cookie = `${name}=; path=/; domain=.devhrassist.app; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure`;
  }
  const clearcall = async () => {
    sessionStorage.setItem("Flag", 0);
    localStorage.setItem("Flag", 0);
    let tid = getTentant();
    if (isInTeams()) {
      clearCookie("userToken");
      clearCookie("userdomain");
      // removeLocalStorageItemOfChildWindow()
      window.open("https://devhrassist.app/signup", "_self");
      let tid = getTentant();
      let domain = getDomain();
      let d1;
      if (domain) {
        d1 = domain.split("/")[1];
      } else {
        let domain = localStorage.getItem("SubDomain");
        d1 = domain.split("/")[1];
      }
      let d2 = window.location.hostname;

      window.open(
        `https://login.microsoftonline.com/${tid}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirecturl}`
      );
    }
    if (mobFlag === true) {
      // CustomMessage("inside mobile", "success", enqueueSnackbar);

      // Directly redirect for mobile
      window.location.href = `https://login.microsoftonline.com/${tid}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirecturl}`;
      localStorage.clear();
    } else {
      const popup = window.open(
        "",
        "_blank",
        `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,menubar=no,titlebar=no`
      );

      if (!popup) {
        console.error("Popup blocked!");
        return;
      }
      popup.location.href = `https://login.microsoftonline.com/${tid}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirecturl}`;
      localStorage.clear();
      // Wait for popup to be ready
      popup.onload = () => {
        popup.location.href = `https://login.microsoftonline.com/${tid}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirecturl}`;
        localStorage.clear();
      };

      window.addEventListener("message", (event) => {
        console.log(event);
      });
    }
  };
  const handleBeforeUnload = (event) => {
    // Clear LocalStorage only when the logout process is successful
    if (localStorage.getItem("Remove") == 1) {
      // localStorage.clear();
    }
  };

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    if (!tok) {
      // navigation("/signup");
      window.open("https://devhrassist.app/signup", "_self");
    } else {
    }
  }, [tok]);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        {getSideFlag() == "true" ? <></> : <Navbar name="Account Settings" />}

        {/* <Sidebar /> */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: getSideFlag() === "true" ? "0px" : "24px",
            paddingTop: { xs: "0px", md: "15px" },
          }}
        >
          {/* <DrawerHeader /> */}

          <Box sx={{ width: "100%" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={2}
                sx={{
                  backgroundColor: "#00A1F1",
                  marginTop: getSideFlag() == "true" ? "0px" : "40px",
                  height: "400px",
                  display: { xs: "none", sm: "none", md: "unset" },
                }}
              >
                {/* <Box
                  className="logo-box"
                  style={{
                    marginTop: "10px",
                    marginLeft: "108px",
                    position: "absolute",
                  }}
                >
                  <img
                    src={details && details.profilePath}
                    style={{ width: "120px" }}
                  ></img>
                </Box> */}
              </Grid>
              <Grid
                item
                xs={12}
                md={10}
                sx={{
                  backgroundColor: "#FFFFFF",
                  marginTop: getSideFlag() == "true" ? "0px" : "40px",
                  height: "400px",
                  paddingLeft: {
                    xs: "20px !important",
                    sm: "35px !important",
                    md: "82px !important",
                  },
                  paddingRight: "15px",
                }}
              >
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                >
                  <Typography
                    className="acc-title"
                    sx={{
                      fontWeight: "500",
                      fontSize: "24px",
                      padding: "35px 35px 5px 81px",
                      paddingLeft: "0px",
                      paddingRight: { xs: "15px", sm: "15px", md: "35px" },
                      paddingTop: { xs: "15px", sm: "15px", md: "35px" },
                    }}
                  >
                    {details &&
                      details.firstName.concat(" ").concat(details.lastName)}
                  </Typography>
                  <Stack direction="row" justifyContent="flex-end">
                    <Box>
                      <Tooltip title="Edit">
                        <IconButton
                          disabled={
                            getRole() == "Admin"
                              ? msIntegrateFlag == 0 ||
                                disconnectFlag == 1 ||
                                permissionAddedFlag == null ||
                                permissionAddedFlag == 0 ||
                                powershellPermissionFlag == null ||
                                powershellPermissionFlag == 0 ||
                                botAppInstallFlag != true ||
                                botAppInstallFlag == 0
                                ? true
                                : false
                              : false
                          }
                          onClick={editProfile}
                        >
                          <img src={editIcon} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <AzureAD provider={authProvider}>
                      {({ authenticationState, accountInfo }) => {
                        let token1 = localStorage.getItem("authority");
                        if (token1) {
                          const base64Url = token1.split(".")[1];
                          const base64 = base64Url
                            .replace(/-/g, "+")
                            .replace(/_/g, "/");
                          const decodedData = JSON.parse(atob(base64));
                          // authenticationState='Authenticated'
                          accountInfo = decodedData;
                        }

                        switch (authenticationState) {
                          case AuthenticationState.Authenticated:
                            return (
                              <>
                                <Box>
                                  <Tooltip title="Logout">
                                    <IconButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // localStorage.clear()
                                        clearcall();
                                        // logout()
                                      }}
                                    >
                                      <img src={outIcon} />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </>
                            );
                          case AuthenticationState.Unauthenticated:
                            return (
                              <>
                                <Box>
                                  <Tooltip title="Logout">
                                    <IconButton
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // localStorage.clear()
                                        clearcall();
                                        // logout()
                                      }}
                                    >
                                      <img src={outIcon} />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </>
                            );
                        }
                      }}
                    </AzureAD>
                  </Stack>
                </Stack>

                <Typography
                  className="acc-email"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {details && details.email}
                </Typography>
                <Stack
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  spacing={{ xs: 0, sm: 2, md: 2 }}
                  gap={{ xs: "15px", sm: "0px", md: "0px" }}
                >
                  <Box
                    component="span"
                    sx={{
                      padding: "4px 8px",
                      borderRadius: "5px",
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      background: "#FFF",
                      height: "30px",
                    }}
                  >
                    <Typography className="acc-uptext">
                      {details && details.role}
                    </Typography>
                    {/* <Typography style={{fontSize:'10px'}}>{details&&details.groupName}</Typography> */}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      padding: "4px 8px",
                      borderRadius: "5px",
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      background: "#FFF",
                      maxHeight: "42px",
                    }}
                  >
                    <Typography className="acc-uptext">
                      Signed up on{" "}
                      {details &&
                        moment(new Date(details.createdDate)).format(
                          "MMMM DD, YYYY "
                        )}
                    </Typography>
                  </Box>
                </Stack>
                {details && (
                  <FormControlLabel
                    className="acc-showtext"
                    style={{ padding: "10px 2px 2px 0px" }}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        value={details && details.awayFlag}
                        checked={details && details.awayFlag === 1}
                      />
                    }
                    label="Show me as away"
                  />
                )}
                <Typography
                  className="acc-about"
                  style={{ padding: "10px 2px 2px 0px" }}
                >
                  ABOUT ME
                </Typography>

                <Typography
                  className="acc-about-para"
                  style={{ padding: "10px 2px 2px 0px" }}
                >
                  {details && details.aboutMe != "null" ? details.aboutMe : ""}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
